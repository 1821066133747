/* @media all and (min-width: 480px) {
    .panel-home {
        margin: 0 auto;
        max-width: 320px;
        padding: 60px 0 0;
    }
} */

.panel-home {
  position: fixed;
  top: 15px;
  right: 140px;
  width: 100px;
}

.panel-home .is-btn {
  width: auto;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.95);
  font-family: sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 400;
  color: #5e5e5e;
  border: transparent 1px solid;
  box-shadow: 0px 3px 6px -6px rgba(0, 0, 0, 0.32);
  opacity: 1;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 0 20px;
  cursor: pointer;
  text-transform: none;
  text-align: center;
  position: relative;
  border-radius: 0;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}
