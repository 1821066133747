.test{
    background-color: red;
}
.tabletd{
    padding: 10px 0;
}
.tabletd p {
    display: flex;
    justify-content: center;
}

.tableLine {
    margin: 0;
}
.tablehead{
    width: 100% ;
    background: rebeccapurple;
}
.CTABLE{
    width: 100%;
}
.tablebdyrt{
    border-bottom: 1px solid black;
}
.tableTH{
    padding: 10px 0;
}
.header{
    width: 100%;
    display: flex;
    gap: 20px;
    padding: 20px;
}